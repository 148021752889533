import HH_0800 from '../assets/img/HH-0800.jpg';
import KSC_7500 from '../assets/img/KSC-7500.jpg';
import SFC_7000 from '../assets/img/SFC-7000.jpg';
import SFP_0100 from '../assets/img/SFP-0100.jpg';
import SHF_0800 from '../assets/img/SHF-0800.jpg';
import SUF_0520 from '../assets/img/SUF-0520.jpg';
import VSS_1000 from '../assets/img/VSS-1000.jpg';
import STV_1 from '../assets/img/STV-1.jpg';
import WJ_C2_CAN from '../assets/img/WJ_C2_CAN.jpg';
import WJ_C2_PREM_ACC_KIT from '../assets/img/WJ_C2_PREM_ACC_KIT.jpg';
// import WJ_C2_PREM_VACACC_KIT from '../assets/img/WJ_C2_PREM_VACACC_KIT.jpg';
import WJ_C2EX from '../assets/img/WJ_C2EX.jpg';
import WJC2_PKG_BAGS from '../assets/img/WJC2_PKG_BAGS.jpg';
import karastandisplay from '../assets/img/karastandisplay.jpg';
import mohawkdisplay from '../assets/img/mohawkdisplay.jpg';

export const google_map_key = 'AIzaSyAJNxPgRbo7xjgz9O3_QwFBuZW8LOviA9o';

const orderTypeList = [
  {
    id: 3,
    name: 'REFERRAL SALE',
    bannerinsalesform:
      'Refer your customer and get credit towards purchases of Soniclean products',
    longdescription: '',
  },
];

const MKTMohawkProducts = [
  {
    _id: 'sfc-display-mhk',
    price: '40000',
    unit: 'unit',
    tooltip: 'Mohawk Soft Carpet Display Stand (vacuum not included)',
    name: 'Mohawk Soft Carpet Display Stand (vacuum not included)',
    description: '',
    image: mohawkdisplay,
    imageurl: 'mohawkdisplay',
    multiples: 1,
  },
];

const MKTKarastanProducts = [
  {
    _id: 'sfc-display-kar',
    price: '40000',
    unit: 'unit',
    tooltip: 'Karastan Soft Carpet Display Stand (vacuum not included)',
    name: 'Karastan Soft Carpet Display Stand (vacuum not included)',
    description: '',
    image: karastandisplay,
    imageurl: 'karastandisplay',
    multiples: 1,
  },
  {
    _id: 'sfc-display-mhk',
    price: '40000',
    unit: 'unit',
    tooltip: 'Mohawk Soft Carpet Display Stand (vacuum not included)',
    name: 'Mohawk Soft Carpet Display Stand (vacuum not included)',
    description: '',
    image: mohawkdisplay,
    imageurl: 'mohawkdisplay',
    multiples: 1,
  },
];

const DemoProducts = [
  {
    _id: 'DEMO-KSC7500',
    price: '20000',
    unit: 'unit',
    tooltip: 'Karastan Soft Carpet Upright Vacuum',
    name: 'Karastan Soft Carpet Upright Vacuum',
    description: '(Model: KSC-7500)',
    image: KSC_7500,
    imageurl: 'KSC_7500',
    multiples: 2,
  },
  {
    _id: 'DEMO-SFC7000',
    price: '20000',
    unit: 'unit',
    tooltip: 'Soniclean Soft Carpet Upright Vacuum',
    name: 'Soniclean Soft Carpet Upright Vacuum',
    description: '(Model: SFC-7000)',
    image: SFC_7000,
    imageurl: 'SFC_7000',
    multiples: 2,
  },
];

const DirectShipKarastanProducts = [
  {
    _id: 'KSC-7500',
    price: '25500',
    unit: 'unit',
    tooltip: 'Karastan Soft Carpet Upright Vacuum',
    name: 'Karastan Soft Carpet Upright Vacuum',
    description: '(Model: KSC-7500)',
    image: KSC_7500,
    imageurl: 'KSC_7500',
    multiples: 2,
    retailMap: '39999',
  },
  {
    _id: 'SFC-7000-MHK',
    price: '25500',
    unit: 'unit',
    tooltip: 'Soft Carpet Upright Vacuum',
    name: 'Soft Carpet Upright Vacuum',
    description: '(Model: SFC-7000)',
    image: SFC_7000,
    imageurl: 'SFC_7000',
    multiples: 2,
    retailMap: '44999',
  },
  {
    _id: 'STV-1',
    price: '16000',
    unit: 'unit',
    tooltip: 'Mohawk Cordless Digital Stick Vacuum ',
    name: 'Mohawk Cordless Digital Stick Vacuum ',
    description: '(Model: STV-1)',
    image: STV_1,
    imageurl: 'STV-1',
    multiples: 2,
    retailMap: '29999',
  },
  {
    _id: 'VSS-1000-MHK',
    price: '75000',
    discount: '75000',
    unit: 'unit',
    tooltip: 'Varro Professional Steam Cleaner',
    name: 'Varro Professional Steam Cleaner',
    description: '(Model: VSS-1000)',
    image: VSS_1000,
    imageurl: 'VSS_1000',
    multiples: 1,
    retailMap: '99999',
  },
  {
    _id: 'WJ-C2-CAN',
    price: '23100',
    discount: '23100',
    unit: 'unit',
    tooltip: 'WhisperJet C2 Canister Vacuum Cleaner',
    name: 'WhisperJet C2 Canister Vacuum Cleaner',
    description: '(Model: WJ-C2-CAN)',
    image: WJ_C2_CAN,
    imageurl: 'WJ_C2_CAN',
    multiples: 1,
    retailMap: '34999',
  },
  // {
  //   _id: 'WJ-C2-PREM VAC/ACC KIT',
  //   price: '29900',
  //   discount: '29900',
  //   unit: 'unit',
  //   tooltip: 'WhisperJet C2 Canister with Premium Accessory Kit',
  //   name: 'WhisperJet C2 Canister with Premium Accessory Kit',
  //   description: '(Model: WJ-C2-PREM VAC/ACC KIT)',
  //   image: WJ_C2_PREM_VACACC_KIT,
  //   imageurl: 'WJ_C2_PREM_VACACC_KIT',
  //   multiples: 1,
  //   retailMap: '44999',
  // },
  // {
  // _id: 'HH-0800',
  // price: '8500',
  // unit: 'unit',
  // tooltip: 'Soniclean Handheld Vacuum',
  // name: 'Soniclean Handheld Vacuum',
  // description: '(Model: HH-0800)',
  // image: HH_0800,
  // imageurl: 'HH_0800',
  // multiples: 2,
  // retailMap: '14999',
  // },
  // {
  // _id: 'SUF-0520-7',
  // price: '2950',
  // unit: 'unit',
  // tooltip: 'Soniclean Upright HEPA Filter Bags (7 Pack)',
  // name: 'Soniclean Upright HEPA Filter Bags (7 Pack)',
  // description: '(Model: SUF-0520)',
  // image: SUF_0520,
  // imageurl: 'SUF_0520',
  // multiples: 2,
  // retailMap: '3499',
  // },
  // {
  // _id: 'SFP-0100',
  // price: '1650',
  // unit: 'unit',
  // tooltip: 'Sonicfresh Fragrance Pods (8 Pack)',
  // name: 'Sonicfresh Fragrance Pods (8 Pack)',
  // description: '(Model: SFP-0100)',
  // image: SFP_0100,
  // imageurl: 'SFP_0100',
  // multiples: 2,
  // retailMap: '1999',
  // },
  // {
  // _id: 'SHF-0800',
  // price: '1650',
  // unit: 'unit',
  // tooltip: 'Soniclean Handheld HEPA Filter Bags (8 Pack)',
  // name: 'Soniclean Handheld HEPA Filter Bags (8 Pack)',
  // description: '(Model: SHF-0800)',
  // image: SHF_0800,
  // imageurl: 'SHF_0800',
  // multiples: 2,
  // retailMap: '1899',
  // },
];

const DirectShipMohawkProducts = [
  {
    _id: 'SFC-7000-MHK',
    price: '25500',
    unit: 'unit',
    tooltip: 'Soft Carpet Upright Vacuum',
    name: 'Soft Carpet Upright Vacuum',
    description: '(Model: SFC-7000)',
    image: SFC_7000,
    imageurl: 'SFC_7000',
    multiples: 2,
    retailMap: '44999',
  },
  {
    _id: 'STV-1',
    price: '16000',
    unit: 'unit',
    tooltip: 'Mohawk Cordless Digital Stick Vacuum ',
    name: 'Mohawk Cordless Digital Stick Vacuum ',
    description: '(Model: STV-1)',
    image: STV_1,
    imageurl: 'STV-1',
    multiples: 2,
    retailMap: '29999',
  },
  {
    _id: 'VSS-1000-MHK',
    price: '75000',
    discount: '75000',
    unit: 'unit',
    tooltip: 'Varro Professional Steam Cleaner',
    name: 'Varro Professional Steam Cleaner',
    description: '(Model: VSS-1000)',
    image: VSS_1000,
    imageurl: 'VSS_1000',
    multiples: 1,
    retailMap: '99999',
  },
  {
    _id: 'WJ-C2-CAN',
    price: '23100',
    discount: '23100',
    unit: 'unit',
    tooltip: 'WhisperJet C2 Canister Vacuum Cleaner',
    name: 'WhisperJet C2 Canister Vacuum Cleaner',
    description: '(Model: WJ-C2-CAN)',
    image: WJ_C2_CAN,
    imageurl: 'WJ_C2_CAN',
    multiples: 1,
    retailMap: '34999',
  },
  // {
  //   _id: 'WJ-C2-PREM VAC/ACC KIT',
  //   price: '29900',
  //   discount: '29900',
  //   unit: 'unit',
  //   tooltip: 'WhisperJet C2 Canister with Premium Accessory Kit',
  //   name: 'WhisperJet C2 Canister with Premium Accessory Kit',
  //   description: '(Model: WJ-C2-PREM VAC/ACC KIT)',
  //   image: WJ_C2_PREM_VACACC_KIT,
  //   imageurl: 'WJ_C2_PREM_VACACC_KIT',
  //   multiples: 1,
  //   retailMap: '44999',
  // },
  // {
  // _id: 'HH-0800',
  // price: '8500',
  // unit: 'unit',
  // tooltip: 'Soniclean Handheld Vacuum',
  // name: 'Soniclean Handheld Vacuum',
  // description: '(Model: HH-0800)',
  // image: HH_0800,
  // imageurl: 'HH_0800',
  // multiples: 2,
  // retailMap: '14999',
  // },
  // {
  // _id: 'SUF-0520-7',
  // price: '2950',
  // unit: 'unit',
  // tooltip: 'Soniclean Upright HEPA Filter Bags (7 Pack)',
  // name: 'Soniclean Upright HEPA Filter Bags (7 Pack)',
  // description: '(Model: SUF-0520)',
  // image: SUF_0520,
  // imageurl: 'SUF_0520',
  // multiples: 2,
  // retailMap: '3499',
  // },
  // {
  // _id: 'SFP-0100',
  // price: '1650',
  // unit: 'unit',
  // tooltip: 'Sonicfresh Fragrance Pods (8 Pack)',
  // name: 'Sonicfresh Fragrance Pods (8 Pack)',
  // description: '(Model: SFP-0100)',
  // image: SFP_0100,
  // imageurl: 'SFP_0100',
  // multiples: 2,
  // retailMap: '1999',
  // },
  // {
  // _id: 'SHF-0800',
  // price: '1650',
  // unit: 'unit',
  // tooltip: 'Soniclean Handheld HEPA Filter Bags (8 Pack)',
  // name: 'Soniclean Handheld HEPA Filter Bags (8 Pack)',
  // description: '(Model: SHF-0800)',
  // image: SHF_0800,
  // imageurl: 'SHF_0800',
  // multiples: 2,
  // retailMap: '1899',
  // },
];

const InventoryKarastanProducts = [
  {
    _id: 'KSC-7500',
    price: '23500',
    discount: '22500',
    unit: 'unit',
    tooltip: 'Karastan Soft Carpet Upright Vacuum',
    name: 'Karastan Soft Carpet Upright Vacuum',
    description: '(Model: KSC-7500)',
    image: KSC_7500,
    imageurl: 'KSC_7500',
    multiples: 2,
    retailMap: '39999',
  },
  {
    _id: 'SFC-7000-MHK',
    price: '23500',
    discount: '22500',
    unit: 'unit',
    tooltip: 'Soft Carpet Upright Vacuum',
    name: 'Soft Carpet Upright Vacuum',
    description: '(Model: SFC-7000)',
    image: SFC_7000,
    imageurl: 'SFC_7000',
    multiples: 2,
    retailMap: '44999',
  },
  {
    _id: 'STV-1',
    price: '14500',
    discount: '13000',
    unit: 'unit',
    tooltip: 'Mohawk Cordless Digital Stick Vacuum',
    name: 'Mohawk Cordless Digital Stick Vacuum',
    description: '(Model: STV-1)',
    image: STV_1,
    imageurl: 'STV-1',
    multiples: 2,
    retailMap: '29999',
  },
  {
    _id: 'VSS-1000-MHK',
    price: '75000',
    discount: '75000',
    unit: 'unit',
    tooltip: 'Varro Professional Steam Cleaner',
    name: 'Varro Professional Steam Cleaner',
    description: '(Model: VSS-1000)',
    image: VSS_1000,
    imageurl: 'VSS_1000',
    multiples: 1,
    retailMap: '99999',
  },
  // {
  // _id: 'HH-0800',
  // price: '8000',
  // discount: '7500',
  // unit: 'unit',
  // tooltip: 'Soniclean Handheld Vacuum',
  // name: 'Soniclean Handheld Vacuum',
  // description: '(Model: HH-0800)',
  // image: HH_0800,
  // imageurl: 'HH_0800',
  // multiples: 2,
  // retailMap: '14999',
  // },
  {
    _id: 'SUF-0520-7-4',
    price: '2800',
    discount: '2800',
    unit: 'package',
    tooltip: 'Soniclean Upright HEPA Filter Bags (CASE)',
    name: 'Soniclean Upright HEPA Filter Bags',
    description: '(Model: SUF-0520)',
    image: SUF_0520,
    imageurl: 'SUF_0520',
    multiples: 4,
    retailMap: '3499',
  },
  {
    _id: 'SFP-0100-4',
    price: '1500',
    discount: '1500',
    unit: 'package',
    tooltip: 'Sonicfresh Fragrance Pods (CASE)',
    name: 'Sonicfresh Fragrance Pods (CASE)',
    description: '(Model: SFP-0100)',
    image: SFP_0100,
    imageurl: 'SFP_0100',
    multiples: 4,
    retailMap: '1999',
  },
  {
    _id: 'WJ-C2-CAN',
    price: '21100',
    discount: '21100',
    unit: 'unit',
    tooltip: 'WhisperJet C2 Canister Vacuum Cleaner',
    name: 'WhisperJet C2 Canister Vacuum Cleaner',
    description: '(Model: WJ-C2-CAN)',
    image: WJ_C2_CAN,
    imageurl: 'WJ_C2_CAN',
    multiples: 2,
    retailMap: '34999',
  },
  // {
  //   _id: 'WJ-C2-PREM VAC/ACC KIT',
  //   price: '26900',
  //   discount: '26900',
  //   unit: 'unit',
  //   tooltip: 'WhisperJet C2 Canister with Premium Accessory Kit',
  //   name: 'WhisperJet C2 Canister with Premium Accessory Kit',
  //   description: '(Model: WJ-C2-PREM VAC/ACC KIT)',
  //   image: WJ_C2_PREM_VACACC_KIT,
  //   imageurl: 'WJ_C2_PREM_VACACC_KIT',
  //   multiples: 2,
  //   retailMap: '44999',
  // },
  {
    _id: 'WJ-C2-PREM ACC KIT',
    price: '7500',
    discount: '7500',
    unit: 'unit',
    tooltip: 'WhisperJet C2 Premium Accessory Kit',
    name: 'WhisperJet C2 Premium Accessory Kit',
    description: '(Model: WJ-C2-PREM ACC KIT)',
    image: WJ_C2_PREM_ACC_KIT,
    imageurl: 'WJ_C2_PREM_ACC_KIT',
    multiples: 2,
    retailMap: '12999',
  },
  {
    _id: 'WJC2-PKG BAGS',
    price: '2800',
    discount: '2800',
    unit: 'package',
    tooltip: 'WhisperJet C2 HEPA Canister Filter Bags (7 Pack)',
    name: 'WhisperJet C2 HEPA Canister Filter Bags (7 Pack)',
    description: '(Model: WJC2-PKG BAGS)',
    image: WJC2_PKG_BAGS,
    imageurl: 'WJC2_PKG_BAGS',
    multiples: 4,
    retailMap: '3499',
  },
  {
    _id: 'WJ-C2EX',
    price: '1550',
    discount: '1550',
    unit: 'package',
    tooltip: 'WhisperJet C2 ULPA Exhaust Filter (1 Pack)',
    name: 'WhisperJet C2 ULPA Exhaust Filter (1 Pack)',
    description: '(Model: WJ-C2EX)',
    image: WJ_C2EX,
    imageurl: 'WJ_C2EX',
    multiples: 4,
    retailMap: '2599',
  },
  // {
  // _id: 'SHF-0800-4',
  // price: '1500',
  // discount: '1500',
  // unit: 'package',
  // tooltip: 'Soniclean Handheld HEPA Filter Bags (CASE)',
  // name: 'Soniclean Handheld HEPA Filter Bags (CASE)',
  // description: '(Model: SHF-0800)',
  // image: SHF_0800,
  // imageurl: 'SHF_0800',
  // multiples: 4,
  // retailMap: '1899',
  // },
];

const InventoryMohawkProducts = [
  {
    _id: 'SFC-7000-MHK',
    price: '23500',
    discount: '22500',
    unit: 'unit',
    tooltip: 'Soft Carpet Upright Vacuum',
    name: 'Soft Carpet Upright Vacuum',
    description: '(Model: SFC-7000)',
    image: SFC_7000,
    imageurl: 'SFC_7000',
    multiples: 2,
    retailMap: '44999',
  },
  {
    _id: 'STV-1',
    price: '14500',
    discount: '13000',
    unit: 'unit',
    tooltip: 'Mohawk Cordless Digital Stick Vacuum',
    name: 'Mohawk Cordless Digital Stick Vacuum',
    description: '(Model: STV-1)',
    image: STV_1,
    imageurl: 'STV-1',
    multiples: 2,
    retailMap: '29999',
  },
  {
    _id: 'VSS-1000-MHK',
    price: '75000',
    discount: '75000',
    unit: 'unit',
    tooltip: 'Varro Professional Steam Cleaner',
    name: 'Varro Professional Steam Cleaner',
    description: '(Model: VSS-1000)',
    image: VSS_1000,
    imageurl: 'VSS_1000',
    multiples: 1,
    retailMap: '99999',
  },
  // {
  // _id: 'HH-0800',
  // price: '8000',
  // discount: '7500',
  // unit: 'unit',
  // tooltip: 'Soniclean Handheld Vacuum',
  // name: 'Soniclean Handheld Vacuum',
  // description: '(Model: HH-0800)',
  // image: HH_0800,
  // imageurl: 'HH_0800',
  // multiples: 2,
  // retailMap: '14999',
  // },
  {
    _id: 'SUF-0520-7-4',
    price: '2800',
    discount: '2000',
    unit: 'package',
    tooltip: 'Soniclean Upright HEPA Filter Bags (CASE)',
    name: 'Soniclean Upright HEPA Filter Bags',
    description: '(Model: SUF-0520)',
    image: SUF_0520,
    imageurl: 'SUF_0520',
    multiples: 4,
    retailMap: '3499',
  },
  {
    _id: 'SFP-0100-4',
    price: '1500',
    discount: '1500',
    unit: 'package',
    tooltip: 'Sonicfresh Fragrance Pods (CASE)',
    name: 'Sonicfresh Fragrance Pods (CASE)',
    description: '(Model: SFP-0100)',
    image: SFP_0100,
    imageurl: 'SFP_0100',
    multiples: 4,
    retailMap: '1999',
  },
  {
    _id: 'WJ-C2-CAN',
    price: '21100',
    discount: '21100',
    unit: 'unit',
    tooltip: 'WhisperJet C2 Canister Vacuum Cleaner',
    name: 'WhisperJet C2 Canister Vacuum Cleaner',
    description: '(Model: WJ-C2-CAN)',
    image: WJ_C2_CAN,
    imageurl: 'WJ_C2_CAN',
    multiples: 2,
    retailMap: '34999',
  },
  // {
  //   _id: 'WJ-C2-PREM VAC/ACC KIT',
  //   price: '26900',
  //   discount: '26900',
  //   unit: 'unit',
  //   tooltip: 'WhisperJet C2 Canister with Premium Accessory Kit',
  //   name: 'WhisperJet C2 Canister with Premium Accessory Kit',
  //   description: '(Model: WJ-C2-PREM VAC/ACC KIT)',
  //   image: WJ_C2_PREM_VACACC_KIT,
  //   imageurl: 'WJ_C2_PREM_VACACC_KIT',
  //   multiples: 2,
  //   retailMap: '44999',
  // },
  {
    _id: 'WJ-C2-PREM ACC KIT',
    price: '7500',
    discount: '7500',
    unit: 'unit',
    tooltip: 'WhisperJet C2 Premium Accessory Kit',
    name: 'WhisperJet C2 Premium Accessory Kit',
    description: '(Model: WJ-C2-PREM ACC KIT)',
    image: WJ_C2_PREM_ACC_KIT,
    imageurl: 'WJ_C2_PREM_ACC_KIT',
    multiples: 2,
    retailMap: '12999',
  },
  {
    _id: 'WJC2-PKG BAGS',
    price: '2800',
    discount: '2800',
    unit: 'package',
    tooltip: 'WhisperJet C2 HEPA Canister Filter Bags (7 Pack)',
    name: 'WhisperJet C2 HEPA Canister Filter Bags (7 Pack)',
    description: '(Model: WJC2-PKG BAGS)',
    image: WJC2_PKG_BAGS,
    imageurl: 'WJC2_PKG_BAGS',
    multiples: 4,
    retailMap: '3499',
  },
  {
    _id: 'WJ-C2EX',
    price: '1550',
    discount: '1550',
    unit: 'package',
    tooltip: 'WhisperJet C2 ULPA Exhaust Filter (1 Pack)',
    name: 'WhisperJet C2 ULPA Exhaust Filter (1 Pack)',
    description: '(Model: WJ-C2EX)',
    image: WJ_C2EX,
    imageurl: 'WJ_C2EX',
    multiples: 4,
    retailMap: '2599',
  },
  // {
  // _id: 'SHF-0800-4',
  // price: '1500',
  // discount: '1500',
  // unit: 'package',
  // tooltip: 'Soniclean Handheld HEPA Filter Bags (CASE)',
  // name: 'Soniclean Handheld HEPA Filter Bags (CASE)',
  // description: '(Model: SHF-0800)',
  // image: SHF_0800,
  // imageurl: 'SHF_0800',
  // multiples: 4,
  // retailMap: '1899',
  // },
];

const InventoryVacuumProducts = [
  {
    _id: 'SFC-7000',
    price: '23500',
    discount: '22500',
    unit: 'unit',
    tooltip: 'Soft Carpet Upright Vacuum',
    name: 'Soft Carpet Upright Vacuum',
    description: '(Model: SFC-7000)',
    image: SFC_7000,
    imageurl: 'SFC_7000',
    multiples: 2,
    retailMap: '44999',
  },
  {
    _id: 'STV-1',
    price: '14500',
    discount: '13000',
    unit: 'unit',
    tooltip: 'Mohawk Cordless Digital Stick Vacuum',
    name: 'Mohawk Cordless Digital Stick Vacuum',
    description: '(Model: STV-1)',
    image: STV_1,
    imageurl: 'STV-1',
    multiples: 2,
    retailMap: '29999',
  },
  {
    _id: 'VSS-1000',
    price: '75000',
    discount: '75000',
    unit: 'unit',
    tooltip: 'Varro Professional Steam Cleaner',
    name: 'Varro Professional Steam Cleaner',
    description: '(Model: VSS-1000)',
    image: VSS_1000,
    imageurl: 'VSS_1000',
    multiples: 1,
    retailMap: '99999',
  },
  // {
  // _id: 'HH-0800',
  // price: '8000',
  // discount: '7500',
  // unit: 'unit',
  // tooltip: 'Soniclean Handheld Vacuum',
  // name: 'Soniclean Handheld Vacuum',
  // description: '(Model: HH-0800)',
  // image: HH_0800,
  // imageurl: 'HH_0800',
  // multiples: 2,
  // retailMap: '14999',
  // },
  {
    _id: 'SUF-0520',
    price: '2500',
    discount: '2500',
    unit: 'package',
    tooltip: 'Soniclean Upright HEPA Filter Bags (CASE)',
    name: 'Soniclean Upright HEPA Filter Bags',
    description: '(Model: SUF-0520)',
    image: SUF_0520,
    imageurl: 'SUF_0520',
    multiples: 4,
    retailMap: '3499',
  },
  {
    _id: 'SFP-0100',
    price: '1250',
    discount: '1250',
    unit: 'package',
    tooltip: 'Sonicfresh Fragrance Pods (CASE)',
    name: 'Sonicfresh Fragrance Pods (CASE)',
    description: '(Model: SFP-0100)',
    image: SFP_0100,
    imageurl: 'SFP_0100',
    multiples: 4,
    retailMap: '1999',
  },
  {
    _id: 'WJ-C2-CAN',
    price: '21100',
    discount: '21100',
    unit: 'unit',
    tooltip: 'WhisperJet C2 Canister Vacuum Cleaner',
    name: 'WhisperJet C2 Canister Vacuum Cleaner',
    description: '(Model: WJ-C2-CAN)',
    image: WJ_C2_CAN,
    imageurl: 'WJ_C2_CAN',
    multiples: 2,
    retailMap: '34999',
  },
  // {
  //   _id: 'WJ-C2-PREM VAC/ACC KIT',
  //   price: '26900',
  //   discount: '26900',
  //   unit: 'unit',
  //   tooltip: 'WhisperJet C2 Canister with Premium Accessory Kit',
  //   name: 'WhisperJet C2 Canister with Premium Accessory Kit',
  //   description: '(Model: WJ-C2-PREM VAC/ACC KIT)',
  //   image: WJ_C2_PREM_VACACC_KIT,
  //   imageurl: 'WJ_C2_PREM_VACACC_KIT',
  //   multiples: 2,
  //   retailMap: '44999',
  // },
  {
    _id: 'WJ-C2-PREM ACC KIT',
    price: '7500',
    discount: '7500',
    unit: 'unit',
    tooltip: 'WhisperJet C2 Premium Accessory Kit',
    name: 'WhisperJet C2 Premium Accessory Kit',
    description: '(Model: WJ-C2-PREM ACC KIT)',
    image: WJ_C2_PREM_ACC_KIT,
    imageurl: 'WJ_C2_PREM_ACC_KIT',
    multiples: 2,
    retailMap: '12999',
  },
  {
    _id: 'WJC2-PKG BAGS',
    price: '2500',
    discount: '2500',
    unit: 'package',
    tooltip: 'WhisperJet C2 HEPA Canister Filter Bags (7 Pack)',
    name: 'WhisperJet C2 HEPA Canister Filter Bags (7 Pack)',
    description: '(Model: WJC2-PKG BAGS)',
    image: WJC2_PKG_BAGS,
    imageurl: 'WJC2_PKG_BAGS',
    multiples: 4,
    retailMap: '3499',
  },
  {
    _id: 'WJ-C2EX',
    price: '1550',
    discount: '1550',
    unit: 'package',
    tooltip: 'WhisperJet C2 ULPA Exhaust Filter (1 Pack)',
    name: 'WhisperJet C2 ULPA Exhaust Filter (1 Pack)',
    description: '(Model: WJ-C2EX)',
    image: WJ_C2EX,
    imageurl: 'WJ_C2EX',
    multiples: 4,
    retailMap: '2599',
  },
  // {
  // _id: 'SHF-0800-4',
  // price: '1500',
  // discount: '1500',
  // unit: 'package',
  // tooltip: 'Soniclean Handheld HEPA Filter Bags (CASE)',
  // name: 'Soniclean Handheld HEPA Filter Bags (CASE)',
  // description: '(Model: SHF-0800)',
  // image: SHF_0800,
  // imageurl: 'SHF_0800',
  // multiples: 4,
  // retailMap: '1899',
  // },
];

const orderType = [
  {
    title: 'Buy Inventory',
    description: 'Order Inventory for your Showroom/warehouse',
    detail:
      'This form allows you to order inventory for your store. Vacuum are sold in multiples of 2 and accessories are sold by the case; one case contains 4 packages of accessories. All prices include freight for orders with shipping addresses within the contiguous US. For more pricing and shipping information, please reference <a href="/programdetails">Sonicleans Dealer Sell Sheet</a>',
  },
  {
    title: 'Direct Ship',
    description:
      'Order 1 vacuum at a time to be shipped to directly to your customer or store',
    detail:
      'This order form allows you place an order containing a maximum of 1 of each type of vacuum. Direct ship orders can be shipped to your customers or to your store location. All prices include freight for orders with shipping addresses within the contiguous US.',
  },
  {
    title: 'Marketing',
    description: 'Order a Soniclean display stand',
    detail:
      'Soniclean vacuum cleaner displays are available for both Mohawk and Karastan branded vacuum cleaners (SFC-7000 & KSC-7500). Our high quality displays are custom made in Chicago and are great for merchandising your display vacuum in your showroom.',
  },
  {
    title: 'Order Demo Unit',
    description: '***Only Visible for Soniclean Personel',
    detail: 'Place an order for a demo unit.',
  },
  // {
  // title: 'REFERRAL SALE',
  // description: 'Refer your customers and earn cash',
  // detail: '',
  // },
];

const PriceType = {
  DIRECTSHIP: 1,
  BUYINVENTORY: 2,
  REFERRALSALE: 3,
};

const shippinginforType = [
  {
    id: 1,
    name: 'Ship to Customer',
    bannerinsalesform: '',
  },
  {
    id: 2,
    name: 'Ship to Store',
    bannerinsalesform: '',
  },
];

const ShippingType = {
  SHIPTOCUSTOMER: 1,
  SHIPTOSTORE: 2,
};

export {
  MKTKarastanProducts,
  MKTMohawkProducts,
  InventoryKarastanProducts,
  InventoryVacuumProducts,
  InventoryMohawkProducts,
  DemoProducts,
  DirectShipKarastanProducts,
  DirectShipMohawkProducts,
  orderType,
  orderTypeList,
  shippinginforType,
  PriceType,
  ShippingType,
  OrderStatus,
  PaymentTypes,
  mohawkPaymentStatus,
};

export const REQUEST_STATUS = {
  INITIAL: 'request_initial',
  PENDING: 'request_pending',
  SUCCESS: 'request_success',
  FAIL: 'request_fail',
};

export const USER_ROLE = {
  DEALER: 'dealer',
  VACUUMDEALER: 'vacuum-dealer',
  OFFICIAL: 'official',
  MANAGER: 'manager',
  EMPLOYEE: 'employee',
};

export const mohawkBrands = [
  {
    label: 'Mohawk',
    value: 'Mohawk',
  },
  {
    label: 'Karastan',
    value: 'Karastan',
  },
];

export const special_product = {
  _id: 'SFC-7000-MHK',
  price: '22500',
  unit: 'unit',
  tooltip: 'Soft Carpet Upright Vacuum',
  name: 'Soft Carpet Upright Vacuum',
  description: '(Model: SFC-7000)',
  image: SFC_7000,
  imageurl: 'SFC_7000',
  multiples: 2,
  retailMap: '44999',
};

export const special_account = {
  account: '109140',
  _id: 'SFC-7000-MHK',
};

export const special_products = [
  {
    _id: 'SFC-7000-MHK',
    price: '22500',
    unit: 'unit',
    tooltip: 'Soft Carpet Upright Vacuum',
    name: 'Soft Carpet Upright Vacuum',
    description: '(Model: SFC-7000)',
    image: SFC_7000,
    imageurl: 'SFC_7000',
    multiples: 2,
    retailMap: '44999',
  },
  {
    _id: 'KSC-7500',
    price: '23500',
    unit: 'unit',
    tooltip: 'Karastan Soft Carpet Upright Vacuum',
    name: 'Karastan Soft Carpet Upright Vacuum',
    description: '(Model: KSC-7500)',
    image: KSC_7500,
    imageurl: 'KSC_7500',
    multiples: 2,
    retailMap: '39999',
  },
];

export const special_accounts = [
  {
    account: '109140',
    products: ['SFC-7000-MHK', 'KSC-7500'],
  },
  {
    account: '109141',
    products: ['SFC-7000-MHK', 'KSC-7500'],
  },
];

const OrderStatus = [
  { value: 'awaiting_shipment', label: 'In Process' },
  { value: 'shipped', label: 'Shipped' },
];

const PaymentTypes = [
  { value: 'stripe', label: 'Stripe' },
  { value: 'mohawk', label: 'Mohawk' },
  { value: 'freepaid', label: 'No Charge' },
];

const mohawkPaymentStatus = [
  { value: 'unpaid', label: 'Mohawk-UNPAID' },
  { value: 'paid', label: 'Mohawk-PAID' },
];

export const allProducts = [
  {
    value: 'sfc-display-kar',
    label: 'sfc-display-kar',
    type: 'MKT',
  },
  {
    value: 'sfc-display-mhk',
    label: 'sfc-display-mhk',
    type: 'MKT',
  },
  {
    value: 'DEMO-KSC7500',
    label: 'DEMO-KSC7500',
    type: 'DEM',
  },
  {
    value: 'DEMO-SFC7000',
    label: 'DEMO-SFC7000',
    type: 'DEM',
  },
  {
    value: 'DEMO-HH0800',
    label: 'DEMO-HH0800',
    type: 'DEM',
  },
  {
    value: 'KSC-7500',
    label: 'KSC-7500',
    type: 'DS-INV',
  },
  {
    value: 'SFC-7000-MHK',
    label: 'SFC-7000-MHK',
    type: 'DS-INV',
  },
  {
    value: 'STV-1',
    label: 'STV-1',
    type: 'DS-INV',
  },
  {
    value: 'HH-0800',
    label: 'HH-0800',
    type: 'DS-INV',
  },
  {
    value: 'SUF-0520-7',
    label: 'SUF-0520-7',
    type: 'DS',
  },
  {
    value: 'SFP-0100',
    label: 'SFP-0100',
    type: 'DS',
  },
  {
    value: 'SHF-0800',
    label: 'SHF-0800',
    type: 'DS',
  },
  {
    value: 'SUF-0520-7-4',
    label: 'SUF-0520-7-4',
    type: 'INV',
  },
  {
    value: 'SFP-0100-4',
    label: 'SFP-0100-4',
    type: 'INV',
  },
  {
    value: 'SHF-0800-4',
    label: 'SHF-0800-4',
    type: 'INV',
  },
  {
    value: 'WJ-C2-CAN',
    label: 'WJ-C2-CAN',
    type: 'DS-INV',
  },
  // {
  //   value: 'WJ-C2-PREM VAC/ACC KIT',
  //   label: 'WJ-C2-PREM VAC/ACC KIT',
  //   type: 'DS-INV',
  // },
  {
    value: 'WJ-C2-PREM ACC KIT',
    label: 'WJ-C2-PREM ACC KIT',
    type: 'INV',
  },
  {
    value: 'WJC2-PKG BAGS',
    label: 'WJC2-PKG BAGS',
    type: 'INV',
  },
  {
    value: 'WJ-C2EX',
    label: 'WJ-C2EX',
    type: 'INV',
  },
];

export const itemAdminCollection = [
  [
    'Order Number',
    'Order Date',
    'Company',
    'Mohawk Account #',
    'Product',
    'SKU',
    'Unit Price',
    'Quantity',
    'Subtotal',
    'Discount',
    'Refund',
    'Total',
    'First Name',
    'Last Name',
    'Shipping Add. 1',
    'Shipping Add. 2',
    'Shipping City',
    'Shipping State',
    'Shipping Zip Code',
    'Phone Number',
    'Dealer Email',
    'Customer Email',
    'Payment Status',
    'Payment Type',
    'Shipping Status',
    'Shipped Date',
    'Tungsten Invoice #',
    'Invoice Paid Date',
    'Mohawk PO #',
    'PO Authorizer',
    'Tracking Information',
    'Notes',
  ],
];

export const itemDearCollection = [
  [
    'Order Number',
    'Order Date',
    'Company',
    'Mohawk Account #',
    'Product',
    'SKU',
    'Unit Price',
    'Quantity',
    'Subtotal',
    'Discount',
    'Refund',
    'Total',
    'First Name',
    'Last Name',
    'Shipping Add. 1',
    'Shipping Add. 2',
    'Shipping City',
    'Shipping State',
    'Shipping Zip Code',
    'Phone Number',
    'Dealer Email',
    'Customer Email',
    'Payment Status',
    'Payment Type',
    'Shipping Status',
    'Tracking Information',
  ],
];
