const namespace = 'soniclean/auth';

export const LOGIN = `${namespace}/login`;
export const ACCEPTNEWPRICE = `${namespace}/login`;
export const LOGOUT = `${namespace}/logout`;
export const REGISTER = `${namespace}/register`;
export const VACUUMREGISTER = `${namespace}/vacuumregister`;
export const FAILURE_USER_LOGIN = `${namespace}/failureuserlogin`;
export const RESTPASSWORD = `${namespace}/resetpassword`;
export const RESTPASSWORDEMAIL = `${namespace}/resetpasswordemail`;
